import axios from "axios";

const instance = axios.create({
  baseURL: "https://standard.winworldle.com/api",
  timeout: 5000,
});

instance.interceptors.request.use(
  (config) => {
    // config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    if (config.url.includes("/sendPlanInfo/exportGrandAlarmList")) {
      config.headers["responseType"] = "arraybuffer";
      config.responseType = "blob";
      config.headers["Content-Type"] = "application/octet-stream";
      console.log(config);
    }
    console.log(config);
    config.headers.Authorization = localStorage;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    const data = response.data;
    if (data.code !== 200) {
      console.error(`Error: ${data.message}`);
    }
    return data;
  },
  (error) => {
    console.error("Response Error:", error);
    return Promise.reject(error);
  }
);

function get(url, params = {}) {
  return instance.get(url, { params });
}

function post(url, data = {}) {
  return instance.post(url, data);
}

function uploadFile(url, file) {
  const formData = new FormData();
  formData.append("file", file);
  return instance.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export { get, post, uploadFile };
