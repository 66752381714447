<template>
  <div>
    <el-form :inline="true" :model="searchForm" class="demo-form-inline">
      <el-form-item label="发货编号">
        <el-input v-model="searchForm.sendNumber" clearable />
      </el-form-item>
      <el-form-item label="客户名称">
        <el-input v-model="searchForm.sendName" clearable />
      </el-form-item>
      <el-form-item label="车牌号">
        <el-input v-model="searchForm.carNum" clearable />
      </el-form-item>
      <el-form-item label="时间">
        <el-date-picker
          v-model="time"
          value-format="YYYY-MM-DD"
          type="datetimerange"
          start-placeholder="Start date"
          end-placeholder="End date"
          format="YYYY-MM-DD HH:mm:ss"
          date-format="YYYY/MM/DD ddd"
          time-format="A hh:mm:ss"
        />
      </el-form-item>
      <el-form-item>
        <el-button @click="addPlanModal">新增发货清单</el-button>
        <el-button type="primary" @click="onSearch">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="sendNumber" label="发货编号" />
      <el-table-column prop="sendName" label="发货单名称" />
      <el-table-column prop="state" label="状态">
        <template v-slot="scope">
          <el-tag v-if="scope.row.state == 0" type="warning">草稿</el-tag>
          <el-tag v-else-if="scope.row.state == 1" type="warning"
            >审核中</el-tag
          >
          <el-tag v-else-if="scope.row.state == 2" type="success">通过</el-tag>
          <el-tag v-else-if="scope.row.state == 3" type="danger">驳回</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="set" label="操作" width="300">
        <template v-slot="scope">
          <el-button type="primary" size="mini" @click="edit(scope.row)"
            >填写清单</el-button
          >
          <el-button type="danger" size="mini" @click="deletePlan(scope.row)"
            >删除清单</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- addPlanModal -->

    <el-dialog
      v-model="visible"
      :close-on-click-modal="false"
      :show-close="false"
      class="custom-dialog"
      style="max-width: 700px"
    >
      <template #header>
        <div class="custom-dialog-header">新增发货清单</div>
      </template>
      <div class="custom-dialog-content">
        <el-form
          ref="ruleFormRef"
          style="max-width: 600px"
          :model="ruleForm"
          :rules="rules"
          label-width="auto"
          class="demo-ruleForm"
          :size="formSize"
          status-icon
        >
          <el-form-item label="清单编号" prop="sendNumber">
            <el-input v-model="ruleForm.sendNumber" />
          </el-form-item>
          <el-form-item label="客户名称" prop="sendName">
            <el-input v-model="ruleForm.sendName" />
          </el-form-item>
          <el-form-item label="发货时间" prop="sendTime">
            <el-date-picker
              style="width: 100%"
              v-model="ruleForm.sendTime"
              type="date"
              :size="size"
              value-format="YYYY-MM-DD"
            />
          </el-form-item>
          <el-form-item label="发货地址" prop="sendAddress">
            <el-input v-model="ruleForm.sendAddress" />
          </el-form-item>
          <el-form-item label="车牌号" prop="carNum">
            <el-input v-model="ruleForm.carNum" />
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <el-button type="default" @click="visible = false">取消</el-button>
        <el-button type="primary" @click="submitForm(ruleFormRef)"
          >确认</el-button
        >
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import { post } from "@/utils/axios";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";

const router = useRouter();
const tableData = ref([]);
const visible = ref(false);
let time = ref("");
const searchForm = ref({
  sendNumber: "",
  sendName: "",
  carNum: "",
  beginTime: "",
  endTime: "",
});
const ruleFormRef = ref();
const ruleForm = ref({
  sendNumber: "",
  sendName: "",
  sendTime: "",
  sendAddress: "",
  carNum: "",
});
const rules = reactive({
  sendNumber: [
    { required: true, message: "请输入发货清单编号", trigger: "blur" },
  ],
  sendName: [{ required: true, message: "请输入客户名称", trigger: "blur" }],
  sendTime: [{ required: true, message: "请选择发货时间", trigger: "blur" }],
  sendAddress: [{ required: true, message: "请输入发货地址", trigger: "blur" }],
  carNum: [{ required: true, message: "请输入车牌号", trigger: "blur" }],
});
const onSearch = () => {
  if (time.value.length > 0) {
    searchForm.value.beginTime = time.value[0] + " 00:00:00";
    searchForm.value.endTime = time.value[1] + " 23:59:59";
  }
  // return;
  getPlan(searchForm.value);
};
const edit = (row) => {
  router.push({
    path: "/allPlan",
    query: { id: row.id },
  });
};
const getPlan = (data = {}) => {
  post("/sendPlan/findSendPlanList", data).then((res) => {
    console.log(res);
    tableData.value = res.data.records;
  });
};
const deletePlan = (row) => {
  post(`/sendPlan/deleteSendPlan?id=${row.id}`).then((res) => {
    ElMessage({
      message: "删除成功!",
      type: "success",
    });
    getPlan();
  });
};
const addPlanModal = () => {
  visible.value = true;
};
const submitForm = async (formEl) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      post("/sendPlan/creatSendPlan", ruleForm.value).then((res) => {
        formEl.resetFields();
        getPlan();
        visible.value = false;
      });
    } else {
      console.log("error submit!", fields);
    }
  });
};
const addPlan = () => {};
onMounted(() => {
  getPlan();
});
</script>

<style lang="scss" scoped></style>
