<template>
  <div>
    <el-button type="primary" @click="addBasket">添加发货筐</el-button>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="planId" label="发货编号" width="180" />
      <!-- <el-table-column prop="sendName" label="发货单名称" width="180" /> -->
      <el-table-column prop="createTime" label="创建时间">
        <template v-slot="scope">
          {{ getTime(scope.row.createTime) }}
        </template>
      </el-table-column>
      <el-table-column prop="picUrl" label="二维码">
        <template v-slot="scope">
          <img
            :src="
              'https://send-plan-1322767194.cos.ap-beijing.myqcloud.com/' +
              scope.row.picUrl
            "
            style="width: 50px; height: 50px; cursor: pointer"
            alt=""
            @click="preview(scope.row.picUrl)"
          />
        </template>
      </el-table-column>
      <el-table-column prop="state" label="状态">
        <template v-slot="scope">
          <el-tag v-if="scope.row.state == 0" type="warning">草稿</el-tag>
          <el-tag v-else-if="scope.row.state == 1" type="warning"
            >审核中</el-tag
          >
          <el-tag v-else-if="scope.row.state == 2" type="success">通过</el-tag>
          <el-tag v-else-if="scope.row.state == 3" type="danger">驳回</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="set" label="操作" width="300">
        <template v-slot="scope">
          <el-button type="primary" size="mini" @click="edit(scope.row)"
            >填写筐</el-button
          >
          <el-button type="primary" size="mini" @click="deleteBasket(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-image
      class="img-preview"
      style="width: 0px; height: 0px"
      :src="previewList[0]"
      :zoom-rate="1.2"
      :max-scale="7"
      :min-scale="0.2"
      :preview-src-list="previewList"
      :preview-teleported="ture"
      :z-index="99999"
      fit="cover"
    />
    <!-- <div class="card-box">
      <el-card
        style="max-width: 480px"
        v-for="(card, cardIndex) in cardList"
        :key="cardIndex"
      >
        <template #header>
          <div class="card-header">
            <span>发货筐1</span>
            <el-button class="add-item" type="primary">添加项</el-button>
          </div>
        </template>
        <div class="card-content">
          <div class="card-column" v-for="(item, index) in card" :key="index">
            <el-input
              v-model="item.name"
              style="width: 240px"
              placeholder="请输入项目"
            />
            <el-input
              v-model="item.num"
              style="width: 240px"
              type="number"
              placeholder="请输入数量"
            />
          </div>
        </div>
        <template #footer>Footer content</template>
      </el-card>
    </div> -->
    <el-dialog v-model="dialogVisible" title="新增筐明细" width="700">
      <el-card style="max-width: 480px; margin: 0 auto">
        <template #header>
          <div class="card-header">
            <span>发货筐1</span>
            <el-button class="add-item" type="primary" @click="addItem"
              >添加项</el-button
            >
          </div>
        </template>
        <div class="card-content">
          <div
            class="card-column"
            v-for="(item, index) in cardList"
            :key="index"
          >
            <el-input
              v-model="item.stockName"
              style="width: 240px"
              placeholder="请输入项目名称"
            />
            <el-input
              v-model="item.stockNum"
              style="width: 240px"
              type="number"
              placeholder="请输入数量"
            />
            <el-input
              v-model="item.unit"
              style="width: 240px"
              placeholder="请输入单位"
            />
            <el-button
              type="danger"
              :icon="Delete"
              circle
              style="margin-left: 12px; text-align: center"
              @click="cardList.splice(index, 1)"
            ></el-button>
          </div>
        </div>
        <template #footer>Footer content</template>
      </el-card>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="addDetails"> 提交 </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { post, get } from "@/utils/axios";
import { Delete } from "@element-plus/icons-vue";

const route = useRoute();
const tableData = ref([]);
const cardList = ref([]);
const dialogVisible = ref(false);
const rowData = ref({});
let previewList = ref([
  "https://send-plan-1322767194.cos.ap-beijing.myqcloud.com/2024/10/23/5b1aabda-4492-4544-b47c-56f890d7e60b.png",
]);
const getData = () => {
  get("/sendPlanBasket/findSendBasketList", {
    planId: route.query.id,
  }).then((res) => {
    console.log(res);
    tableData.value = res.data.records;
  });
};
const addBasket = () => {
  post("/sendPlanBasket/creatSendBasket", {
    planId: route.query.id,
  }).then((res) => {
    getData();
  });
};
const deleteBasket = (row) => {
  post(`/sendPlanBasket/deleteSendBasket?id=${row.id}`).then((res) => {
    getData();
  });
};
const edit = (row) => {
  rowData.value = row;
  get("/sendPlanInfo/findSendInfoPlan", {
    basketId: rowData.value.id,
  }).then((res) => {
    cardList.value = res.data.map((item) => {
      return {
        stockName: item.stockName,
        stockNum: item.stockNum,
        unit: item.unit,
      };
    });
    dialogVisible.value = true;
  });
};
const addItem = () => {
  cardList.value.push({
    stockName: "",
    stockNum: 0,
    unit: "",
  });
};
const addDetails = () => {
  console.log(cardList.value);
  post("/sendPlanInfo/creatSendInfoPlan", {
    basketId: rowData.value.id,
    infos: cardList.value,
  });
};
const preview = (url) => {
  previewList.value = [
    "https://send-plan-1322767194.cos.ap-beijing.myqcloud.com/" + url,
  ];
  console.log(document.querySelector(".img-preview img"));
  document.querySelector(".img-preview img").click();
};
const getTime = (timestamp) => {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // 月份从0开始，需要加1
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  // 拼接成你需要的格式
  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return formattedDate;
};
onMounted(() => {
  getData();
});
</script>

<style lang="scss" scoped>
.card-box {
}
.card-header {
  position: relative;
  .add-item {
    position: absolute;
    right: 12px;
    top: 0px;
  }
}
.card-column {
  display: flex;
}
.qr-code img {
  width: 50px;
  height: 50px;
}
</style>
